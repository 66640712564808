export const buttonStyles = {
  primary:
    'fui-cursor-pointer fui-border fui-border-transparent fui-bg-themeV3-navy-100 fui-text-white focus:fui-outline hover:fui-bg-theme-navy-950 focus:fui-outline-4 focus:fui-outline-theme-navy-150 disabled:fui-bg-theme-dimmed disabled:fui-text-theme-surface-placeholder focus:fui-bg-themeV3-navy-100',
  secondary:
    'fui-border fui-border-white  fui-text-white hover:fui-bg-themeV3-navy-100 hover:fui-text-white focus:fui-border-transparent focus:fui-text-themeV3-navy-100 focus:fui-outline focus:fui-outline-4 focus:fui-outline-theme-navy-150 disabled:fui-border-theme-dimmed disabled:fui-text-theme-surface-placeholder fui-bg-transparent',
  ghost:
    'fui-border fui-border-transparent fui-bg-transparent fui-text-theme-navy-900 hover:fui-bg-theme-dimmed hover:fui-text-theme-navy-900 focus:fui-border-transparent focus:fui-bg-transparent focus:fui-text-theme-navy-900 focus:fui-outline focus:fui-outline-4 focus:fui-outline-theme-navy-150 disabled:fui-border-transparent disabled:fui-bg-theme-dimmed disabled:fui-text-theme-surface-placeholder',
  ternary:
    'fui-border fui-border-white fui-bg-white fui-text-md fui-text-navy-700 hover:fui-bg-white disabled:fui-cursor-not-allowed disabled:fui-border-black-400 disabled:fui-bg-black-400',
  outlinePrimary:
    'fui-border fui-border-navy-300 fui-bg-transparent fui-text-md fui-text-black-600 hover:fui-border-navy-500 hover:fui-text-navy-700 disabled:fui-cursor-not-allowed disabled:fui-border-black-400 disabled:fui-text-black-400',
  addToCart:
    'fui-border fui-border-navy-500 fui-bg-transparent fui-text-md fui-text-navy-500 disabled:fui-cursor-not-allowed disabled:fui-border-black-400 disabled:fui-text-black-400',
  primaryUnderline: 'ltr:fui-mr-1 rtl:fui-ml-1 fui-underline fui-text-navy-500',
  basicUnderline: 'ltr:fui-mr-1 rtl:fui-ml-1 fui-underline',
  primaryText: 'ltr:fui-mr-1 rtl:fui-ml-1 fui-text-navy-500',
  basicText: 'ltr:fui-mr-1 rtl:fui-ml-1',
  primaryWhite:
    'fui-cursor-pointer fui-border fui-border-transparent fui-bg-white fui-text-themeV3-navy-100 focus:fui-outline-white disabled:fui-bg-theme-dimmed disabled:fui-text-theme-surface-placeholder focus:fui-bg-white fui-text-md',
};

export type TButtonStyles = keyof typeof buttonStyles;

export const buttonSizes = {
  default:
    'fui-font-semibold fui-px-45px md:fui-px-25px xl:fui-px-45px xl:fui-h-[50px] fui-text-base lg:fui-text-md fui-font-enFounderRegular rtl:fui-font-arabicContent fui-h-[44px]',
  md: 'fui-px-4.5 fui-text-base fui-font-enFounderRegular rtl:fui-font-arabicContent fui-h-[48px]',
  sm: 'fui-px-3.5 fui-text-md fui-font-enFounderRegular rtl:fui-font-arabicContent fui-h-[38px]',
};

export type TButtonSizes = keyof typeof buttonSizes;

export const buttonRoundedStyles = {
  none: 'fui-rounded-none',
  default: 'fui-rounded-full',
};

export type TButtonRoundedStyles = keyof typeof buttonRoundedStyles;

export const buttonTextCases = {
  lower: 'fui-lowercase',
  upper: 'fui-uppercase',
  normal: 'fui-normal-case',
};

export type TButtonTextCases = keyof typeof buttonTextCases;

export const buttonShadow = {
  default: 'fui-shadow-none',
  button: 'fui-shadow-button',
};

export type TButtonShadow = keyof typeof buttonShadow;
