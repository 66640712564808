import * as React from 'react';
import type { SVGProps, Ref } from 'react';
import { forwardRef } from 'react';

const IconCircleCheck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>
) => (
  <svg
    ref={ref}
    {...props}
    width='27'
    height='27'
    viewBox='0 0 27 27'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 13.5C0 6.09677 6.04234 0 13.5 0C20.9032 0 27 6.09677 27 13.5C27 20.9577 20.9032 27 13.5 27C6.04234 27 0 20.9577 0 13.5ZM20.2077 8.20413L21.4597 9.45615C21.6774 9.67389 21.6774 10.1094 21.4597 10.3816L12.0423 19.69C11.7702 19.9622 11.3891 19.9622 11.1169 19.69L6.16331 14.682C5.94556 14.4642 5.94556 14.0287 6.16331 13.7566L7.41532 12.559C7.6875 12.2868 8.06855 12.2868 8.34073 12.559L11.6069 15.8251L19.2823 8.20413C19.5544 7.93196 19.9899 7.93196 20.2077 8.20413Z'
      fill='currentColor'
    />
  </svg>
);

const ForwardRef = forwardRef(IconCircleCheck);
export default ForwardRef;
