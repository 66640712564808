import { useEffect } from 'react';
import { useLocalTranslator } from '@/hooks/useLocalTranslator';
import { InputField } from '@flowardco/fui-input-field';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useValidations } from '@flowardco/flib-hooks';
import { Button } from '@flowardco/uiv3/Button';
import IconCircleCheck from '@flowardco/uiv3/Icons/src/IconCircleCheck';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { AppErrorBoundary } from '@flowardco/fui-app-error-boundary';
import { resetPassword } from '@/api/id.service';
import { useLocalApp } from '@/hooks/useLocalApp';
import { twMerge } from 'tailwind-merge';

export interface FormValues {
  newPassword: string;
  confirmedPassword: string;
}
export const ResetPasswordForm = () => {
  const { __T } = useLocalTranslator();
  const { validations } = useValidations(__T);
  const [isProcessing, setIsProcessing] = useState(false);
  const { appState } = useLocalApp();

  const handleSubmit = async (values: FormValues) => {
    try {
      setIsProcessing(true);
      if (values.newPassword.trim() !== values.confirmedPassword.trim()) {
        toast.error(__T('Passwords are not identical.'));
      } else {
        const query = new URLSearchParams(location.search);
        const token = query.get('tokenId');
        const tokenString = query.get('tokenString') || appState?.tokenString;
        const data = {
          newPassword: values.newPassword,
          token: tokenString,
        };
        if (token && tokenString) {
          //   Add your password reset API call or other logic
          const res = await resetPassword(token, data, __T);
          if (res) {
            window.location.href = 'https://floward.com/';
          }
        }
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const initialValues: FormValues = {
    newPassword: '',
    confirmedPassword: '',
  };

  const validationSchema = Yup.object().shape({
    newPassword: validations.password,
    confirmedPassword: validations.password,
  });

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema,
  });
  useEffect(() => {
    formik.validateForm(); // Triggers form validation on page load to avoid true password checks validation
  }, []);
  const inputFieldClass =
    'fui-mb-2 fui-rounded-xl fui-h-[52px] fui-border-theme-neutral-850 placeholder:fui-text-base placeholder:ltr:fui-font-enFounderRegular placeholder:fui-text-themeV3-green-50 fui-px-4';
  const hasLowerCaseError =
    !/[a-z]/.test(formik.values.newPassword) && formik.errors.newPassword;

  const hasUpperCaseError =
    !/[A-Z]/.test(formik.values.newPassword) && formik.errors.newPassword;

  const hasMinLengthError =
    formik.values.newPassword.length < 8 && formik.errors.newPassword;
  return (
    <div className=''>
      <h5 className='fui-mb-4 fui-block fui-text-center fui-text-[28px] fui-font-semibold fui-text-themeV3-navy-100 ltr:fui-font-enMontasLight md:fui-text-[32px]'>
        {__T('Reset Password')}
      </h5>
      <AppErrorBoundary>
        <form
          onSubmit={formik.handleSubmit}
          className='fui-flex fui-w-full fui-flex-col fui-items-start fui-justify-center'
        >
          <InputField
            label={__T('New password')}
            id='newPassword'
            labelClass='fui-text-base fui-mb-2 fui-font-bold fui-text-themeV3-navy-100'
            name='newPassword'
            type='password'
            wrapperClassName='fui-w-full'
            inputFieldClass={inputFieldClass}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.newPassword && formik.touched.newPassword
                ? formik.errors.newPassword
                : undefined
            }
            value={formik.values.newPassword}
          />
          <InputField
            label={__T('Confirm new password')}
            id='confirmedPassword'
            labelClass='fui-text-base fui-mb-2 fui-font-bold fui-text-themeV3-navy-100'
            name='confirmedPassword'
            type='password'
            wrapperClassName='fui-w-full'
            inputFieldClass={inputFieldClass}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorMessage={
              formik.errors.confirmedPassword &&
              formik.touched.confirmedPassword
                ? formik.errors.confirmedPassword
                : undefined
            }
            value={formik.values.confirmedPassword}
          />
          <div className='fui-mt-2 fui-flex fui-w-full fui-flex-wrap'>
            <p className='fui-block fui-w-full fui-text-base fui-text-themeV3-navy-100 ltr:fui-font-enFounderRegular'>
              {__T('Your password must contain')}:
            </p>
            <ul className='fui-mb-8 fui-mt-4 fui-w-full fui-flex-col fui-space-y-2 ltr:fui-font-enFounderRegular'>
              <li className='fui-flex fui-items-center fui-text-base fui-text-themeV3-navy-100'>
                <IconCircleCheck
                  className={twMerge(
                    'fui-h-5 fui-w-5 fui-rounded-full fui-border',
                    hasLowerCaseError
                      ? 'fui-border-themeV3-navy-100 fui-text-white'
                      : ''
                  )}
                />
                <span className='fui-ms-2'>
                  {__T('Lower case letters (password).')}
                </span>
              </li>
              <li className='fui-flex fui-items-center fui-text-base fui-text-themeV3-navy-100'>
                <IconCircleCheck
                  className={twMerge(
                    'fui-h-5 fui-w-5 fui-rounded-full fui-border',
                    hasUpperCaseError
                      ? 'fui-border-themeV3-navy-100 fui-text-white'
                      : ''
                  )}
                />
                <span className='fui-ms-2'>
                  {__T('Upper case letters (PASSWORD).')}
                </span>
              </li>
              <li className='fui-flex fui-items-center fui-text-base fui-text-themeV3-navy-100'>
                <IconCircleCheck
                  className={twMerge(
                    'fui-h-5 fui-rounded-full fui-border ltr:fui-w-23px rtl:!fui-w-22px ',
                    hasMinLengthError
                      ? 'fui-border-themeV3-navy-100 fui-text-white'
                      : ''
                  )}
                />
                <span className='fui-ms-2'>
                  {__T('At least 8 characters including numbers')}
                </span>
              </li>
            </ul>
          </div>
          <Button
            label={__T('Confirm')}
            type='submit'
            className='fui-mb-4 fui-mt-4 fui-h-[52px] fui-w-full fui-text-base'
            isProcessing={isProcessing}
          />
        </form>
      </AppErrorBoundary>
    </div>
  );
};
